<template>
  <div>
    <v-row v-if="isLoading" no-gutters class="d-flex justify-space-between">
      <v-col cols="12">
        <v-card
          class="round"
          elevation="0 mb-3"
          outlined
        >
          <v-skeleton-loader
            :height="height"
            type="card"
          ></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-carousel
      v-else
      :continuous="true"
      :cycle="true"
      :show-arrows="arrow"
      hide-delimiter-background
      hide-delimiters
      delimiter-icon="mdi-minus"
      :height="height"
      class="round"
    >
      <v-carousel-item
        class="pointer round"
        @click="goTo(list.link)"
        v-for="list in dataBanner"
        :key="list.id"
        :src="list.photo"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
      isLoading:true,
      cycle: true,
      arrow:false,
      height:0,
      dataBanner:[],
    }
  },
  mounted(){
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
    this.loadBanners()
  },
  methods:{
    loadBanners(){
      this.isLoading = true;
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'dashboard/getBanner/').then(response => {
          if(response){
            let data = response.data.data;
            let endPoint
            if(this.arrow){ //desktop
              endPoint = 'dashboard/getImage/desktop_'
            } else { //mobile
              endPoint = 'dashboard/getImage/'
            }
            for(let a=0; a < data.length; a++){
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + endPoint+data[a].photo,
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.dataBanner = data
            setTimeout(() => { 
              this.isLoading = false
            }, 1000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    goTo(link){
      this.$router.push({name:link}); 
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.height = 150
        this.arrow = false
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.height = 150
        this.arrow = false
      }else {
        this.height = 300
        this.arrow = true
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>
